<template>
    <v-container>
        <v-card>
            <v-card-title>
                <div class="d-flex justify-space-between align-center flex-wrap text-subtitle-1 text-uppercase" style="width: 100%; gap: 16px;">
                    <h1 style="word-break: break-word;">
                        <NavButton style="transform: translateY(-1px)" />
                        Seguimiento de procesos
                    </h1>
                    <v-btn
                        color="secondary"
                        class="d-block px-4 py-2 font-weight-bold"
                        style="height: unset !important; min-height: 36px !important;"
                        outlined
                        @click.stop="generarReporte"
                        :loading="generacionReporte.isLoading"
                    >
                        <v-icon class="mr-2" color="secondary">mdi-file-excel</v-icon>
                        Reporte
                    </v-btn>
                </div>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <RetryDataLoading 
                    :loadable="departamento"
                    :retryHandler="cargarDepartamentoUsuarioLogueado"
                >
                    <v-row align="center" justify="end" dense>
                        <v-col v-bind="gridColumnas.codigo_proceso">
                            <v-text-field 
                                v-model="filters.codigo_proceso"
                                label="Código de proceso"
                                placeholder="Ingrese un código"
                                hide-details
                                clearable
                                outlined
                            />
                        </v-col>
                        <v-col v-bind="gridColumnas.id_estado">
                            <v-select 
                                v-model="filters.id_estado"
                                :items="estados.data"
                                :loading="estados.isLoading"
                                label="Estado de seguimiento"
                                placeholder="Seleccione un estado"
                                item-value="id"
                                item-text="nombre"
                                hide-details
                                outlined
                                clearable
                            />
                        </v-col>
                        <v-col v-bind="gridColumnas.rango_fechas">
                            <v-menu
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-text-field
                                            v-model="textoFiltroRangoFechas"
                                            outlined
                                            label="Desde - Hasta"
                                            placeholder="Seleccione un rango"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            hide-details
                                            clearable
                                            @click:clear="limpiarRangoFechas"
                                        />
                                    </span>
                                </template>
                                <v-date-picker
                                    v-model="filters.rango_fechas"
                                    title="es"
                                    :max="fechaMaximaRangoFechas"
                                    range
                                    locale="es-Es"
                                />
                            </v-menu>   
                        </v-col>
                        <v-col v-if="esJefeDeSeguimiento" v-bind="gridColumnas.responsable">
                            <ProgresiveLoadAutocomplete 
                                v-model="filters.responsable"
                                :pageable="responsables"
                                :loading="responsables.isLoading"
                                label="Responsable de seguimiento"
                                placeholder="Seleccione un responsable"
                                item-text="nombre"
                                item-value="id"
                                hide-details
                                @search="buscarResponsables"
                                @load-more="cargarMasResponsables"
                                clearable
                            />
                        </v-col>
                        <v-col v-if="esJefeDeSeguimiento" v-bind="gridColumnas.asignado_a">
                            <ProgresiveLoadAutocomplete 
                                v-model="filters.asignado_a"
                                :pageable="asignados"
                                :loading="asignados.isLoading"
                                class="filtro-seguimiento"
                                label="Asignado a seguimiento"
                                placeholder="Seleccione un asignado"
                                item-text="nombre"
                                item-value="id"
                                hide-details
                                @search="buscarAsignados"
                                @load-more="cargarMasAsignados"
                                clearable
                            />
                        </v-col>
                        <v-col v-bind="gridColumnas.acciones">
                            <div class="d-flex align-center" style="gap: 8px;">
                                <v-btn @click.stop="limpiarSeguimientos" class="flex-grow-1 flex-shrink-1" color="secondary" x-large>
                                    Limpiar
                                </v-btn>
                                <v-btn @click.stop="buscarSeguimientos" class="flex-grow-1 flex-shrink-1" color="primary" x-large>
                                    Buscar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
        
                    <DataTableComponent 
                        :headers="headers"
                        :items="seguimientos.data"
                        :show_loading="seguimientos.isLoading"
                        :total_registros="totalRows"
                        v-models:pagina="pagina"
                        v-models:select="registrosPorPagina"
                        class="mt-4"
                        @paginar="manejarPaginacionRegistros"
                        dense
                        no-gutters
                    >
                        <template v-slot:item.estado_seguimiento="{ item }">
                            <v-chip 
                                label
                                :color="`${item.es_sp.color_fondo} !important`"
                                :text-color="`${item.es_sp.color_texto} !important`"
                            >
                                {{ item.es_sp.nombre ?? 'Sin revisar' }}
                            </v-chip>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{  formatDate(item.created_at)  }}
                        </template>
                        <template v-slot:item.fecha_revision="{ item }">
                            {{  formatDate(item.fecha_revision)  }}
                        </template>
                        <template v-slot:item.asignado="{ item }">
                            {{ formatearNombrePersona(item.us_as) }}
                        </template>
                        <template v-slot:item.responsable="{ item }">
                            {{ formatearNombrePersona(item.us_res) }}
                        </template>
                        <template v-slot:item.estado_observatorio="{ item }">
                            <v-chip
                                label
                                :color="`${item.ProcesoCompra.seg_p_obs?.es_spo.color_fondo} !important`"
                                :text-color="`${item.ProcesoCompra.seg_p_obs?.es_spo.color_texto} !important`"
                            >
                                {{ item.ProcesoCompra.seg_p_obs?.es_spo.nombre ?? 'Sin revisar' }}
                            </v-chip>
                        </template>
                        <template v-slot:item.acciones="{ item }">
                            <div class="d-flex align-center justify-center" style="gap: 4px;">
                                <v-tooltip v-if="esJefeDeSeguimiento" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-on="on"
                                            v-bind="attrs"
                                            icon 
                                            color="secondary"
                                            :disabled="cambioVisibilidadAsignacion.isLoading"
                                            @click.stop="confirmarOcultamientoAsignacion(item)"
                                        >
                                            <v-icon>mdi-eye-lock</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Ocultar</span>
                                </v-tooltip>
        
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            v-on="on"
                                            v-bind="attrs"
                                            icon 
                                            color="secondary"
                                            :to="`/seguimiento-procesos/detalle/${item.id}`"
                                        >
                                            <v-icon>mdi-arrow-right-thick</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Ver detalles</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </DataTableComponent>
                </RetryDataLoading>
            </v-card-text>
        </v-card>

        <SpinnerComponent :spinning="departamento.isLoading" text="Cargando departamento del usuario" />
        <ConfirmationModalComponent
            :is-open="confirmacionOcultamientoAbierta"
            :is-loading="cambioVisibilidadAsignacion.isLoading"
            description="¿Desea ocultar esta asignación?"
            @cancel="cerrarConfirmacionOcultamiento"
            @confirm="ocultarSeguimiento"
        />
    </v-container>
</template>
<script>
import { Listener, Observable } from '@/utils/observable';
import { mapState, mapActions, mapMutations } from "vuex";
import { RetryDataLoading, SpinnerComponent, ProgresiveLoadAutocomplete, ConfirmationModalComponent } from '@/components/utils';
import DataTableComponent from "@/components/DataTableComponent.vue";
import { omit } from 'lodash';
import { NavButton } from '@/components/utils';
import { formatDate } from '@/utils/datetime';
import { joinList } from '@/utils/text';
import moment from 'moment';
import { 
    createPageable,
    createLoadable,
    togglePageable,
    toggleLoadable,
    setPageableResponse,
    setLoadableResponse,
    shouldFetch,
    isResponseSuccesful,
} from '@/utils/loadable';

const filtrosIniciales = {
    rango_fechas: null,
    codigo_proceso: null,
    id_estado: null,
    responsable: null,
    asignado_a: null,
};

export default {
    components: { SpinnerComponent, RetryDataLoading, DataTableComponent, ProgresiveLoadAutocomplete, NavButton, ConfirmationModalComponent },
    data: () => ({
        logoutUnsubscriber: null,
        headers: [
            {
                text: "Código proceso",
                value: "ProcesoCompra.codigo_proceso",
                sortable: false,
                align: "center",
            },
            {
                text: "Nombre proceso",
                value: "ProcesoCompra.nombre_proceso",
                sortable: false,
                align: "center",
            },
            {
                text: 'Institución',
                value: 'ProcesoCompra.Institucion.nombre',
                sortable: false,
                align: 'center',
            },
            {
                text: "Fecha creación",
                value: "created_at",
                sortable: false,
                align: "center",
            },
            {
                text: "Fecha revisión",
                value: "fecha_revision",
                sortable: false,
                align: "center",
            },
            {
                text: "Responsable",
                value: "responsable",
                sortable: false,
                align: "center",
            },
            {
                text: "Asignado",
                value: "asignado",
                sortable: false,
                align: "center",
            },
            {
                text: "Estado",
                value: "estado_seguimiento",
                sortable: false,
                align: "center",
            },
            {
                text: "Estado observatorio",
                value: "estado_observatorio",
                sortable: false,
                align: "center",
            },
            {
                text: "Acción",
                value: "acciones",
                sortable: false,
                align: "center",
            },
        ],
        // Data
        seguimientos: createPageable([], 10),
        filters: { ...filtrosIniciales },
        pagina: 1,
        registrosPorPagina: 10,
        // Responsables
        responsables: createPageable([], 10),
        filtrosResponsables: {
            pagina: 1,
            registrosPorPagina: 25,
            nombre: '',
        },
        asignados: createPageable([], 10),
        filtrosAsignados: {
            pagina: 1,
            registrosPorPagina: 25,
            nombre: '',
        },
        // Ocultar seguimientos
        asignacionActiva: false,
        confirmacionOcultamientoAbierta: false,
        cambioVisibilidadAsignacion: createLoadable(null),
        // Reporte
        generacionReporte: createLoadable(null),
    }),
    computed: {
        ...mapState("seguimientoProcesos", ["departamento", "estados"]),
        totalRows() {
            return this.seguimientos.pagination.total_rows;
        },
        esJefeDeSeguimiento() {
            return this.haveRoles([
                "ROLE_JEFE_SYC_SEGUIMIENTO_PROCESOS_VIEW", 
                "ROLE_JEFE_FISCALIZACION_SEGUIMIENTO_PROCESOS_VIEW"
            ]);
        },
        textoFiltroRangoFechas() {
            if (!this.filters.rango_fechas || !Array.isArray(this.filters.rango_fechas)) return '';

            const [inicio, fin] = this.filters.rango_fechas;

            return `${this.formatDate(inicio, '', 'DD/MM/YYYY')} - ${this.formatDate(fin, '', 'DD/MM/YYYY')}`;
        },
        fechaMaximaRangoFechas() {
            return moment().format('YYYY-MM-DD');
        },
        gridColumnas() {
            if (this.esJefeDeSeguimiento) {
                return ({
                    codigo_proceso: { cols: 12, md: 6, xl: 4 },
                    id_estado: { cols: 12, md: 6, xl: 4 },
                    rango_fechas: { cols: 12, xl: 4 },
                    responsable: { cols: 12, md: 6, xl: 4 },
                    asignado_a: { cols: 12, md: 6, xl: 4 },
                    acciones: { cols: 12, xl: 4 },
                });
            }

            return ({
                codigo_proceso: { cols: 12, md: 6, lg: 3, xl: 2 },
                id_estado: { cols: 12, md: 6, lg: 3, xl: 2 },
                rango_fechas: { cols: 12, md: 6, lg: 3, xl: 2 },
                acciones: { cols: 12, md: 6, lg: 3, xl: 2 },
            });
        },
    },
    methods: {
        ...mapMutations("seguimientoProcesos", ["resetDepartamentoUsuarioLogueado"]),
        ...mapActions("seguimientoProcesos", ["cargarDepartamentoUsuarioLogueado", "cargarCatalogoDeEstados"]),
        formatDate(fecha, fallback = '-', format = 'DD-MM-YYYY HH:mm:ss') {
            return fecha ? formatDate(fecha, format) : fallback;
        },
        formatearNombrePersona(usuario, fallback = 'Sin asignar') {
            const persona = usuario?.Empleado?.Persona;
            
            if (!persona) return fallback;

            const elementosNombre = [
                persona.primer_nombre, 
                persona.segundo_nombre,
                persona.tercer_nombre,
                persona.primer_apellido,
                persona.segundo_apellido, 
                persona.apellido_casada,
            ];

            return joinList(elementosNombre, ' ');
        },
        limpiarRangoFechas() {
            this.filters.rango_fechas = null;
        },
        async cargarListaSeguimientos() {
            const filtros = {
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.pagina,
                // Filtros
                ...omit(this.filters, ['rango_fechas']),
                desde: this.filters.rango_fechas?.[0] ?? null,
                hasta: this.filters.rango_fechas?.[1] ?? null,
            };

            togglePageable(this.seguimientos);
            const { data, headers } = await this.services.SeguimientoProcesos.cargarAsignaciones(filtros);
            setPageableResponse(this.seguimientos, data, headers);

            if (isResponseSuccesful(data) && data.data.length === 0 && this.pagina > 1) {
                this.pagina = this.pagina - 1;
                this.cargarListaSeguimientos();
            }
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.pagina = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.cargarListaSeguimientos();
        },
        buscarSeguimientos() {
            this.pagina = 1;
            this.cargarListaSeguimientos();
        },
        limpiarSeguimientos() {
            this.filters = { ...filtrosIniciales };
            this.pagina = 1;
            this.cargarListaSeguimientos();
        },
        // Responsables
        async cargarListaResponsables() {
            const filtros = {
                pagination: true,
                per_page: this.filtrosResponsables.registrosPorPagina,
                page: this.filtrosResponsables.pagina,
                nombre: this.filtrosResponsables.nombre,
            };
            togglePageable(this.responsables);
            const { data, headers } = await this.services.SeguimientoProcesos.obtenerTecnicosDepartamento(filtros);
            setPageableResponse(this.responsables, data, headers);
        },
        async buscarResponsables(termino) {
            if (this.filtrosResponsables.nombre  === termino) return;

            this.responsables.data = [];
            this.filtrosResponsables.nombre = termino;
            this.filtrosResponsables.pagina = 1;
            this.cargarListaResponsables();
        },
        async cargarMasResponsables() {
            if (this.responsables.isLoading) return;
            const { page, per_page, total_rows } = this.responsables.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows)) return;

            this.filtrosResponsables.pagina = page + 1;
            this.filtrosResponsables.registrosPorPagina = per_page;
            this.cargarListaResponsables();
        },
        // Asignados
        async cargarListaAsignados() {
            const filtros = {
                pagination: true,
                per_page: this.filtrosAsignados.registrosPorPagina,
                page: this.filtrosAsignados.pagina,
                nombre: this.filtrosAsignados.nombre,
            };
            togglePageable(this.asignados);
            const { data, headers } = await this.services.SeguimientoProcesos.obtenerTecnicosDepartamento(filtros);
            setPageableResponse(this.asignados, data, headers);
        },
        async buscarAsignados(termino) {
            if (this.filtrosAsignados.nombre  === termino) return;

            this.asignados.data = [];
            this.filtrosAsignados.nombre = termino;
            this.filtrosAsignados.pagina = 1;
            this.cargarListaAsignados();
        },
        async cargarMasAsignados() {
            if (this.asignados.isLoading) return;
            const { page, per_page, total_rows } = this.asignados.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows)) return;

            this.filtrosAsignados.pagina = page + 1;
            this.filtrosAsignados.registrosPorPagina = per_page;
            this.cargarListaAsignados();
        },
        async ocultarSeguimiento() {
            const idAsignacion = this.asignacionActiva.id;
            const payload = { ocultar: true };

            toggleLoadable(this.cambioVisibilidadAsignacion);
            const { data } = await this.services.SeguimientoProcesos.cambiarVisibilidad(idAsignacion, payload);
            this.confirmacionOcultamientoAbierta = false;
            setLoadableResponse(this.cambioVisibilidadAsignacion, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarListaSeguimientos();
        },
        confirmarOcultamientoAsignacion(asignacion) {
            this.asignacionActiva = { ...asignacion };
            this.confirmacionOcultamientoAbierta = true;
        },
        cerrarConfirmacionOcultamiento(visible) {
            this.confirmacionOcultamientoAbierta = visible;
        },
        // Reporte
        async generarReporte() {
            toggleLoadable(this.generacionReporte);
            const { data } = await this.services.SeguimientoProcesos.generarReporteAsignaciones();
            setLoadableResponse(this.generacionReporte, data, { skipOnSuccess: true, showAlertOnSuccess: true });
        },
    },
    created() {
        this.cargarCatalogoDeEstados();
        this.cargarListaSeguimientos();
        if (shouldFetch(this.departamento)) this.cargarDepartamentoUsuarioLogueado();

        if (this.esJefeDeSeguimiento) {
            this.cargarListaResponsables();
            this.cargarListaAsignados();
        }

        const listener = new Listener('seguimiento-view').setHandler(this.resetDepartamentoUsuarioLogueado);
        this.logoutUnsubscriber = Observable.getInstance().subscribe('logout', listener);
    },
    beforeDestroy() {
        this.logoutUnsubscriber?.();
    },
}
</script>
